import SidePopup from "~/components/common/SidePopup";
import AddAdmissionRequirementsData from "./components/AddAdmissionRequirementsData";
import AdmissionRequirementData from "./components/AdmissionRequirementData";

const AdmissionRequirements = () => {
  return (
    <>
      <AdmissionRequirementData />
      <SidePopup component={<AddAdmissionRequirementsData />} />
    </>
  );
};

export default AdmissionRequirements;
