import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { GET_ADMISSION_REQUIREMENTS } from "~/graphql/def_admission_requirements/Query";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { DELETE_ADMISSION_REQUIREMENTS_WITH_ID } from "~/graphql/def_admission_requirements/Mutation";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditAdmissionRequirementsData from "../EditAdmissionRequirementsData";

const AdmissionRequirementData = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedQualification, setSelectedQualification] = useState<any>(null);
  const client = useApolloClient();
  const { data } = useQuery(GET_ADMISSION_REQUIREMENTS);

  const [deleteAdmissionRequirements] = useMutation(
    DELETE_ADMISSION_REQUIREMENTS_WITH_ID
  );
  const handleDeleteAdmissionRequirements = async (id: string) => {
    const res = await deleteAdmissionRequirements({
      variables: {
        id,
      },
    });
    if (res?.data?.delete_def_admission_requirements?.affected_rows > 0) {
      toast.success("Admission Deleted Successfully!");
      client.refetchQueries({
        include: "all",
      });
    }
  };

  const handleEditUniversityCourse = (requirement: any) => {
    setSelectedQualification(requirement);
    setOpenPopup(true); // Open the popup
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Qualification</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Gap Allowed</TableCell>
              <TableCell>Scholarship Available</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.def_admission_requirements?.map((requirement: any) => (
              <TableRow key={requirement.id}>
                <TableCell>{requirement.qualification}</TableCell>
                <TableCell>{requirement.grades}</TableCell>
                <TableCell>{requirement.gap_allowed}</TableCell>
                <TableCell>{requirement.scholarship_available}</TableCell>
                <TableCell>
                  <div className="flex space-x-5">
                    <Button
                      color="primary"
                      onClick={() => {
                        handleEditUniversityCourse(requirement);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      color="error"
                      onClick={() => {
                        handleDeleteAdmissionRequirements(requirement.id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditAdmissionRequirementsData
              selectedQualification={selectedQualification}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default AdmissionRequirementData;
