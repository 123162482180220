import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { INSERT_UNIVERSITY_COURSE } from "~/graphql/def_university_courses/Mutation";

const AddCourses = () => {
  const { register, handleSubmit, reset } = useForm();
  const { uni_id } = useParams();
  const client = useApolloClient();

  const [insertCourse] = useMutation(INSERT_UNIVERSITY_COURSE);

  const onSubmit = async (data: any) => {
    // try {
    const res = await insertCourse({
      variables: {
        tuitionFees: data.tuitionFee,
        name: data.course,
        universityId: uni_id,
      },
    });

    if (res.data.insert_def_university_courses.affected_rows > 0) {
      client.refetchQueries({
        include: "all",
      });
      toast.success("Course added successfully!");
      reset();
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Courses Data
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Course Name"
          variant="outlined"
          fullWidth
          style={{
            marginBottom: "20px",
          }}
          {...register("course")}
        />
        <TextField
          label="Course Tuition Fee"
          variant="outlined"
          fullWidth
          style={{
            marginBottom: "20px",
          }}
          {...register("tuitionFee")}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddCourses;
