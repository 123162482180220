import SidePopup from "~/components/common/SidePopup";
import AddCourses from "../components/AddCourses";

import CoursesData from "./CoursesData";

const Courses = () => {
  return (
    <>
      <CoursesData />
      <SidePopup component={<AddCourses />} />
    </>
  );
};

export default Courses;
