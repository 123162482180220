import add from "../../../../assets/img/Add.svg";
import addDark from "../../../../assets/img/Add-dark.svg";
import priceBreakDown from "../../../../assets/img/PriceBreakdown.svg";
import priceBreakDownDark from "../../../../assets/img/PriceBreakdown-dark.svg";
import edit from "../../../../assets/img/Edit.svg";
import editDark from "../../../../assets/img/Edit-dark.svg";
import remove from "../../../../assets/img/Delete.svg";
import removeDark from "../../../../assets/img/Delete-dark.svg";
import { DELETE_SUGGESTION } from "../SuggestionBox/graphql/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { toast } from "react-toastify";
import { useState } from "react";
import AddSuggestion from "../AddSuggestion";
import EditSuggestion from "../SuggestionBox/components/EditSuggestion";
import CostBreakDown from "../SuggestionBox/components/CostBreakDown";
import SelectButton from "../SuggestionBox/components/SelectButton";
import { useEffect } from "react";

export default function SuggestionNav({
  suggestions,
  active,
  setActive,
  inquiry,
}: any) {
  const [deleteSuggestion] = useMutation(DELETE_SUGGESTION);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();
  const userRole = useUserDefaultRole();
  const user_id = useUserId();
  const userDefaultRole = useUserDefaultRole();
  const userLoggedIn = useUserId();
  const [showAddModal, setShowAddModal] = useState<any>(false);
  const [showEditModal, setShowEditModal] = useState<any>(false);
  const [showCostBreakdownModal, setShowCostBreakdownModal] =
    useState<any>(false);

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const removeSuggestion = async (id: any) => {
    setWait(true);
    const res: any = await deleteSuggestion({
      variables: { id: id },
    });

    if (res?.data?.delete_inq_suggestion_by_pk?.id) {
      toast.success("Suggestion deleted successfully");
      await client.resetStore();
    } else {
      toast.error("Something went wrong!");
    }
    setWait(false);
  };

  const activeSuggestion = suggestions.find((sg: any) => sg.id === active);

  // {/* <div className=" flex self-end justify-end">
  //       {inquiry.status !== "completed" &&
  //         (user_id === inquiry?.picked_by || userDefaultRole === "admin") &&
  //         inquiry?.status !== "refunded" &&
  //         inquiry?.status !== "completed" && (
  //           <button
  //             onClick={() => setShowAddModal(!showAddModal)}
  //             className="ml-4 mt-2 px-4 py-1 border border-basic text-basic rounded-full inline-block hover:bg-basic hover:text-white tansition-all hover:cursor-pointer"
  //           >
  //             Add Suggestion
  //           </button>
  //         )}
  //     </div> */}

  return (
    <div
      className={`self-stretch flex flex-row py-2 px-0 items-center gap-[10px] bg-white dark:bg-dark-secondary ${
        suggestions.length
          ? `rounded-t-2xl dark:bg-dark-secondary justify-start pt-4 pb-[5px] pt-0`
          : `rounded-lg justify-between`
      } shadow px-4 `}
    >
      {suggestions.length > 0 ? (
        <div
          className={`flex-1 flex flex-row items-center justify-start gap-[10px]`}
        >
          {suggestions.map((sg: any, i: any) => (
            <>
              <>
                <div
                  key={i}
                  onClick={() => {
                    setActive(sg?.id);
                  }}
                  className={`cursor-pointer flex flex-row py-0 pr-2.5 pl-0 items-center justify-start ${
                    active === sg.id
                      ? `text-white rounded-3xl py-1 bg-basic`
                      : `text-basic dark:text-dark-primary`
                  }`}
                >
                  <div className="flex flex-row py-0 pr-0 pl-2.5 items-start justify-start">
                    <b className="relative leading-[20px]">
                      Suggestion # {i + 1}
                    </b>
                  </div>
                </div>
              </>
            </>
          ))}
        </div>
      ) : (
        <div className="text-basic dark:text-dark-primary font-bold capitalize">
          Suggestion
        </div>
      )}
      <div className="flex gap-3">
        {inquiry?.status !== "completed" && (
          <>
            {suggestions.length > 0 && (
              <>
                <SelectButton
                  selected={
                    inquiry?.suggestion_id === activeSuggestion?.id
                      ? true
                      : false
                  }
                  brand_domain={inquiry?.brd_list?.domain}
                  suggestion={activeSuggestion}
                  inquiry={inquiry}
                  inquiry_id={inquiry?.id}
                  user_email={inquiry?.users?.email}
                  brand_url={inquiry?.brd_list?.subdomain}
                  brand_name={inquiry?.brd_list?.name}
                  selected_suggestion_id={inquiry?.suggestion_id}
                  picked_by={inquiry?.picked_by}
                  user_id={inquiry?.users?.id}
                />
                <img
                  src={darkMode ? priceBreakDownDark : priceBreakDown}
                  alt=""
                  className="cursor-pointer"
                  title="Price Breakdown"
                  onClick={() => setShowCostBreakdownModal(true)}
                />
                {(userLoggedIn === inquiry?.picked_by ||
                  userRole === "admin") && (
                  <img
                    src={darkMode ? editDark : edit}
                    alt=""
                    title="Edit Suggestion"
                    className="cursor-pointer"
                    onClick={() => {
                      setShowEditModal(!showEditModal);
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
        {inquiry.status !== "completed" &&
          (user_id === inquiry?.picked_by || userDefaultRole === "admin") &&
          inquiry?.status !== "refunded" &&
          inquiry?.status !== "completed" && (
            <img
              src={darkMode ? addDark : add}
              alt=""
              title="Add Suggestion"
              className="cursor-pointer"
              onClick={() => setShowAddModal(!showAddModal)}
            />
          )}

        {suggestions.length > 0 &&
          (userLoggedIn === inquiry?.picked_by || userRole === "admin") && (
            <>
              {!(inquiry?.suggestion_id === activeSuggestion?.id) && (
                <img
                  src={darkMode ? removeDark : remove}
                  alt=""
                  className="cursor-pointer"
                  title="Delete Suggestion"
                  onClick={() => removeSuggestion(active)}
                />
              )}
            </>
          )}
      </div>
      {showAddModal && (
        <AddSuggestion
          setShowAddModal={setShowAddModal}
          inquiry_id={inquiry.id}
          inquiry={inquiry}
          brand_name={inquiry.brd_list.name}
          brand_url={inquiry.brd_list.subdomain}
          brand_domain={inquiry.brd_list.domain}
          passengers={inquiry?.inq_passengers}
          arrival={inquiry?.to}
          user_id={inquiry?.users?.id}
          user_email={inquiry?.users?.email}
          user_phone={inquiry?.users?.phoneNumber}
          setActive={setActive}
        />
      )}
      {showEditModal && activeSuggestion && (
        <EditSuggestion
          suggestion={activeSuggestion}
          setShowAddModal={setShowEditModal}
          inquiry={inquiry}
          inquiry_id={inquiry.id}
          passengers={inquiry.inq_passengers}
          arrival={inquiry?.to}
          user_id={inquiry.users.id}
        />
      )}

      {showCostBreakdownModal && (
        <CostBreakDown
          inq_suggestion_cost={activeSuggestion.inq_suggestion_costs}
          setShowCostBreakdownModal={setShowCostBreakdownModal}
        />
      )}
    </div>
  );
}
