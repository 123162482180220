import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";

const AddCampuses = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data: any) => {
    console.log("Data", data);
    reset();
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Campuses Data
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Campus Name"
          variant="outlined"
          fullWidth
          className="mb-5"
          style={{
            marginBottom: "20px",
          }}
          {...register("course")}
        />
        {/* <TextField
          label="Course Tuition Fee"
          variant="outlined"
          fullWidth
          className="mb-5"
          style={{
            marginBottom: "20px",
          }}
          {...register("tuitionFee")}
        /> */}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddCampuses;
