import SidePopup from "~/components/common/SidePopup";
import AddCampuses from "../components/AddCampuses";
import { useParams } from "react-router-dom";

const Campuses = () => {

    const { uni_id } = useParams();

    console.log("vcjw", uni_id)
  return (
    <>
      <SidePopup component={<AddCampuses />} />
    </>
  );
};

export default Campuses;