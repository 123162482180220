import { gql } from "@apollo/client";

export const INSERT_DEFAULT_UNIVERSITY = gql`
  mutation InsertUniversity($universityName: String!) {
    insert_def_universities(objects: { name: $universityName }) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY_WITH_ID = gql`
  mutation DeleteUniversityWithId($id: uuid!) {
    delete_def_universities(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_UNIVERSITY_WITH_ID = gql`
  mutation UpdateUniversity($universityId: uuid!, $name: String!) {
    update_def_universities(
      where: { id: { _eq: $universityId } }
      _set: { name: $name }
    ) {
      affected_rows
    }
  }
`;

