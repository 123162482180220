import React, { useEffect, useState } from "react";
import InvoiceCard from "./components/InvoiceCard";
import { toast } from "react-toastify";
import { ADD_INVOICE, EDIT_INQUIRY } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import useEmail from "~/hooks/emails/useEmail";
import ConfrimPopup from "~/components/common/ConfirmPopup";
import moment from "moment";
import { URLs } from "~/config/enums";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { nhost } from "~/lib/nhost";
import { SEND_EMAIL } from "~/graphql/actions/gmail/Mutation";
import InvoiceDetail from "./components/InvoiceCard/components/InvoiceDetail";
import { pdf } from "@react-pdf/renderer";
import {
  UPDATE_INQ_STATUS,
  UPDATE_INQ_UPDATE_USER,
} from "~/graphql/inq_list/Mutation";
import PDFDocument from "./components/InvoiceCard/components/InvoiceDetail/pdfDocument";
import { UPDATE_INQ_INVOICE_PDF } from "~/graphql/inq_invoice_items/Mutation";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

import { UPDATE_THP_HOUSE } from "~/graphql/thp_list/Mutation";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";

export default function Invoices({ inquiry }: any) {
  const [hideForm, setHideForm] = useState(false);
  const [addInvoice] = useMutation(ADD_INVOICE);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVoid, setIsLoadingVoid] = useState(false);
  const client = useApolloClient();
  const [voidConfirm, setVoidConfirm] = useState(false);
  const [generateConfirm, setGenerateConfirm] = useState(false);
  const [runEmailFunc, setRunEmailFunc] = useState(false);

  const user_id = useUserId();

  const customUrl = GetCustomUrl(window.location.host);

  const [updateThpHouse] = useMutation(UPDATE_THP_HOUSE);

  const { thpHouse } = useThpListDetail(customUrl);

  const runFunc1 = async () => {};
  // runFunc1();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const [updateInqInvoicePdf] = useMutation(UPDATE_INQ_INVOICE_PDF);

  const emailSend = useEmail();

  const userDefaultRole = useUserDefaultRole();

  const [sendEmail] = useMutation(SEND_EMAIL);
  const [updateInqStatus] = useMutation(UPDATE_INQ_STATUS);

  function generateRandomNumber() {
    return Math.floor(Math.random() * 1000000); // Adjust the range as needed
  }

  const [logo, setLogo] = useState<any>();
  const [signedImage, setSignedImage] = useState<any>();

  async function convertLogo() {
    try {
      const response = await fetch(
        URLs.FILE_URL + inquiry?.brd_list?.brd_details?.[0]?.fav_icon
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  async function convertSignedImage() {
    try {
      const response = await fetch(
        URLs.FILE_URL +
          inquiry?.inq_invoice?.inq_invoice_items?.[0]?.sign_invoice
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignedImage(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  useEffect(() => {
    if (inquiry?.inq_invoice?.inq_invoice_items?.[0]?.sign_invoice) {
      convertSignedImage();
    }
  }, [inquiry?.inq_invoice?.inq_invoice_items?.[0]?.sign_invoice]);

  useEffect(() => {
    if (inquiry?.brd_list?.brd_details?.[0]?.fav_icon) {
      convertLogo();
    }
  }, [inquiry?.brd_list?.brd_details?.[0]?.fav_icon]);

  const sendEmailtoUser = async () => {
    try {
      const res = await sendEmail({
        variables: {
          data: {
            toEmails: [inquiry?.users?.email],
            subject: "Sign Your Invoice",
            content: `Sign Your inovoice <a target="_blank" href="https://www.${inquiry?.brd_list?.domain}/sign-invoice/${inquiry?.id}">Click here to Sign Invoice</a>`,
            from: "admin@etravelo.co.uk",
            refresh_token: inquiry?.brd_list?.gmail_refresh_token,
            attachments: [],
            iata: "No iata",
          },
        },
      });
      if (res?.data?.sendEmail?.status) {
        toast.success("Invoice Successfully Send to Email");
        await updateInqStatus({
          variables: {
            invoiceId: inquiry?.invoice_id,
          },
        });
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const sendInvoiceToEmail = () => {
    try {
      sendEmailtoUser();
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const uploadPdfToNhost = async (file: any) => {
    try {
      const response = await nhost.storage.upload({
        file,
      });

      if (response.error) {
        console.error("Upload error:", response.error.message);
        throw new Error(response.error.message);
      }

      return response.fileMetadata.id;
    } catch (error) {
      console.error("Failed to upload PDF:", error);
      throw error;
    }
  };

  const updatePDF = async () => {
    const blob = await pdf(
      <PDFDocument
        inquiry={inquiry}
        invoice={inquiry?.inq_invoice}
        logo={logo}
        signedImage={signedImage}
      />
    ).toBlob();

    // Step 2: Convert Blob to File
    const file = new File([blob], "invoice.pdf", {
      type: "application/pdf",
    });

    const pdfId = await uploadPdfToNhost(file);

    await updateInqInvoicePdf({
      variables: {
        invoiceId: inquiry?.invoice_id,
        pdfId: pdfId,
      },
    });
  };

  useEffect(() => {
    if (runEmailFunc) {
      // sendInvoiceToEmail();
      updatePDF();
    }
  }, [inquiry?.invoice_id]);

  const generateInvoice = async () => {
    if (inquiry.suggestion_id) {
      setIsLoading(true);
      const invoiceItems: any = [];
      inquiry.selected_suggestion.inq_suggestion_costs.map((cost: any) => {
        invoiceItems.push({
          amount: cost.sale_price,
          description: `${cost?.inq_passenger?.first_name} ${cost?.inq_passenger?.last_name} (${cost?.inq_passenger?.type})`,
        });
      });

      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: user_id },
      });

      const invoice = {
        inquiry_id: inquiry.id,
        invoice_no: `'${generateRandomNumber()}'`,
        due_date: inquiry.selected_suggestion?.due_date || null,
        inq_invoice_items: {
          data: invoiceItems,
        },
        brd_id: inquiry?.brd_id,
        suggestion_id: inquiry?.suggestion_id,
      };

      const invoiceRes = await addInvoice({
        variables: { invoice },
      });

      if (invoiceRes.data.insert_inq_invoices.affected_rows > 0) {
        await updateThpHouse({
          variables: {
            custom_url: customUrl,
            user_id: user_id,
            data: {
              invoice_credits: thpHouse?.[0]?.invoice_credits - 1,
            },
          },
        });

        const editInquiryRes = await editInquiry({
          variables: {
            id: inquiry.id,
            invoice_id: invoiceRes.data.insert_inq_invoices.returning[0].id,
            status: "awaiting_for_sign",
          },
        });

        const invoiceAmount = invoiceItems?.reduce(
          (sum: any, obj: any) => sum + parseFloat(obj.amount),
          0
        );
        const dateIssued: any = moment(inquiry?.inq_invoice?.created_at).format(
          "DD MMM YYYY hh:mm a"
        );

        // const emailVariables: any = {
        //   inquiryNo: inquiry?.inquiry_no,
        //   userFirstName: inquiry?.users?.displayName,
        //   userLastName: "",
        //   consultantName: inquiry.picked_user?.displayName,
        //   brandName: inquiry?.brd_list?.name,
        //   invoiceNo: invoice.invoice_no,
        //   dateIssued: dateIssued,
        //   dateDue: inquiry.selected_suggestion?.due_date,
        //   totalAmount: invoiceAmount,
        //   fileLink: URLs.SAMPLE_PDF,
        //   fileName: invoice?.invoice_no,
        // };

        // await emailSend(
        //   14,
        //   inquiry.brd_id,
        //   inquiry.users?.id,
        //   inquiry.users?.email,
        //   inquiry.users?.phoneNumber,
        //   emailVariables
        // );

        await client.resetStore();
        setIsLoading(false);
        toast.success("Invoice generated");
      }
    } else {
      toast.error("Suggestion not selected!");
    }
  };

  const voidInvoice = async () => {
    setIsLoadingVoid(true);

    await updateInqListUser({
      variables: { id: inquiry.id, updated_by: user_id },
    });

    const editInquiryRes = await editInquiry({
      variables: {
        id: inquiry.id,
        invoice_id: null,
        status: "assigned",
      },
    });

    const invoiceAmount = inquiry?.inq_invoice.inq_invoice_items?.reduce(
      (sum: any, obj: any) => sum + parseFloat(obj.amount),
      0
    );
    const dateIssued: any = moment(inquiry?.inq_invoice?.created_at).format(
      "DD MMM YYYY hh:mm a"
    );

    // const emailVariables: any = {
    //   inquiryNo: inquiry?.inquiry_no,
    //   userFirstName: inquiry?.users?.displayName,
    //   userLastName: "",
    //   consultantName: inquiry.picked_user?.displayName,
    //   brandName: inquiry?.brd_list?.name,
    //   invoiceNo: inquiry?.inq_invoice?.invoice_no,
    //   dateIssued: dateIssued,
    //   totalAmount: invoiceAmount,
    //   fileLink: URLs.SAMPLE_PDF,
    //   fileName: `${inquiry?.inq_invoice?.invoice_no}-VOIDED`,
    // };

    // await emailSend(
    //   13,
    //   inquiry.brd_id,
    //   inquiry.users?.id,
    //   inquiry.users?.email,
    //   inquiry.users?.phoneNumber,
    //   emailVariables
    // );
    await client.resetStore();
    setIsLoadingVoid(false);
    toast.success("Invoice void");
  };
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      {/* <div className="self-stretch flex  pt-0 px-0 pb-[5px] items-center justify-between border-b-[1px] border-solid border-[#E7E3FC61]">
        <p className="text-basic font-medium mt-2">{`Invoices`}</p>

        {(user_id === inquiry?.picked_by || userDefaultRole === "admin") &&
        inquiry?.status !== "refunded" &&
        thpHouse?.[0]?.invoice_credits > 0 &&
        inquiry?.status !== "completed" ? (
          <div className="">
            {!inquiry.invoice_id ? (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => {
                    setGenerateConfirm(true);
                  }}
                  className="block border border-basic font-medium rounded-full px-3 py-1 text-white hover:text-basic dark:hover:bg-dark-primary cursor-pointer bg-basic hover:bg-white"
                >
                  {isLoading ? "Generating..." : "Generate Invoice"}
                </button>
              </div>
            ) : (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => {
                    setVoidConfirm(true);
                  }}
                  className="block border border-basic font-medium rounded-full px-3 py-1 cursor-pointer text-white dark:hover:bg-dark-primary hover:text-basic bg-basic hover:bg-white"
                >
                  {isLoadingVoid ? "Voiding..." : "Void Invoice"}
                </button>
              </div>
            )}
          </div>
        ) : (
          thpHouse?.[0]?.invoice_credits < 1 && (
            <p className="text-red-500 font-bold">
              You have used all your invoice credits
            </p>
          )
        )}
      </div> */}

      <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
        <hr className="w-[2%] dark:border-[#E7E3FC61]" />
        <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
          INVOICES
        </span>
        <hr className="w-full dark:border-[#E7E3FC61]" />
        {(user_id === inquiry?.picked_by || userDefaultRole === "admin") &&
        inquiry?.status !== "refunded" &&
        thpHouse?.[0]?.invoice_credits > 0 &&
        inquiry?.status !== "completed" ? (
          <div className="min-w-fit pl-2">
            {!inquiry.invoice_id ? (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => {
                    setGenerateConfirm(true);
                  }}
                  className="block border border-basic font-medium rounded-full px-3 py-1 text-white hover:text-basic dark:hover:bg-dark-primary cursor-pointer bg-basic hover:bg-white"
                >
                  {isLoading ? "Generating..." : "Generate Invoice"}
                </button>
              </div>
            ) : (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => {
                    setVoidConfirm(true);
                  }}
                  className="block border border-basic font-medium rounded-full px-3 py-1 cursor-pointer text-white dark:hover:bg-dark-primary hover:text-basic bg-basic hover:bg-white"
                >
                  {isLoadingVoid ? "Voiding..." : "Void Invoice"}
                </button>
              </div>
            )}
          </div>
        ) : (
          thpHouse?.[0]?.invoice_credits < 1 && (
            <p className="text-red-500 font-bold">
              You have used all your invoice credits
            </p>
          )
        )}
      </div>

      {voidConfirm && (
        <ConfrimPopup
          closeModal={() => setVoidConfirm(false)}
          confirm={() => {
            setRunEmailFunc(false);
            voidInvoice();
          }}
        />
      )}
      {generateConfirm && (
        <ConfrimPopup
          closeModal={() => setGenerateConfirm(false)}
          confirm={() => {
            setRunEmailFunc(true);
            generateInvoice();
          }}
        />
      )}
      <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 items-start justify-start gap-[30px] text-mini">
        {inquiry.inq_invoices.map((invoice: any) => (
          <InvoiceCard
            isActive={inquiry.invoice_id === invoice.id ? true : false}
            invoice={invoice}
            inquiry={inquiry}
          />
        ))}
      </div>
    </div>
  );
}
