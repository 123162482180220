type Flight = {
  airline: string;
  airline_no: string;
  seatNo: string;
  date: string;
  departure: string;
  arrival: string;
  class: string;
  departureTime: string;
  arrivalTime: string;
};

type FlightData = {
  depart: Flight[];
  return: Flight[];
};

export function parseFlightData(data: any, arrival: any): FlightData {
  const rawData = data
    .split("\n") // split the string into an array of lines
    .map((line: any) => line.replace(/\s+/g, " ").trim());
  const flightData: FlightData = { depart: [], return: [] };

  let departStop = 1;
  rawData.forEach((line: any, index: any) => {
    const elements = line.split(/\s+/);
    if (arrival === elements[6].substring(3, 6)) departStop = index + 1;
  });

  rawData.forEach((line: any, index: any) => {
    // Split the string by spaces
    const elements = line.split(/\s+/);

    const prevArrDate = index > 0 ? rawData[index - 1].split(/\s+/)[5] : null;

    const flight: Flight = {
      airline: elements[2],
      airline_no: elements[3],
      seatNo: elements[3] + " " + elements[4],
      date: elements[5],
      departure: elements[6].substring(0, 3),
      arrival: elements[6].substring(3, 6),
      class: elements[7],
      departureTime: convertDateFormat(elements[5], elements[8], prevArrDate),
      arrivalTime: convertDateFormat(elements[5], elements[9], elements[5]),
    };

    if (index < departStop) {
      // Push to appropriate array depending on the index
      flightData.depart.push(flight);
    } else {
      flightData.return.push(flight);
    }
  });

  return flightData;
}

let nextYear = false;

function convertDateFormat(dateString: any, timeString: any, prevDate: any) {
  // Input strings
  const finalTime = timeString.replace("#", "");
  // Extract the date parts
  const isDateIncremented = timeString.startsWith("#");
  const dateWithoutSymbol = dateString;
  const dateParts = dateWithoutSymbol.match(/(\d+)(\w+)/);

  const prevDateParts = prevDate ? prevDate.match(/(\d+)(\w+)/) : null;

  const day = parseFloat(dateParts[1]);
  const monthName = dateParts[2];
  const prevMonthName = prevDateParts ? prevDateParts[2] : null;

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const month: any = monthNames.indexOf(monthName);
  console.log("skjfgsdjf", month, new Date().getMonth());
  const prevMonth: any = prevMonthName
    ? monthNames.indexOf(prevMonthName)
    : null;

  const currMonthInd = new Date().getMonth();

  if ((prevMonth === 11 && month === 0) || month < currMonthInd) {
    nextYear = true;
  }

  const year =
    nextYear === true ? new Date().getFullYear() + 1 : new Date().getFullYear(); // Assuming current year
  let dateObject: any = new Date(year, month, day);
  // ;
  dateObject = isDateIncremented ? addOneDayToDate(dateObject) : dateObject;

  // Convert time string to JavaScript date object
  const hour = parseFloat(finalTime.substr(0, 2));
  const minute = parseFloat(finalTime.substr(2, 2));
  // const timeObject = new Date(year, month, day, hour, minute);

  // Combine date and time objects
  // dateObject.setHours(hour, minute);

  // const dateArr: any = getDateComponents(dateObject);
  // Format date in the desired format
  // const formattedDate = dateObject.toISOString();
  const datefinalOjb = new Date(dateObject);
  const fday = datefinalOjb.getDate();
  const fmonth = datefinalOjb.getMonth() + 1; // Add 1 to the month to get the correct number
  const fyear = datefinalOjb.getFullYear();

  const formattedDate = `${fday.toString().padStart(2, "0")}/${fmonth
    .toString()
    .padStart(2, "0")}/${fyear} ${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`;

  return formattedDate;
}

function addOneDayToDate(dateString: any) {
  var date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return date.toString();
}
