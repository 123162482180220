import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import {
  INSERT_BRD_SOCIAL_AUTHENTICATION,
  UPDATE_BRD_SOCIAL_AUTHENTICATION,
} from "~/graphql/brd_social_authentication/Mutation";
import SocialAccountsTable from "./components/SocialAccountsTable";
import { useUserDefaultRole } from "@nhost/react";

import { getLinkedinProfileData } from "~/utils/getLinkedinProfileData";
import { GET_SOCIAL_AUTHENTICATION_INFO } from "~/graphql/brd_social_authentication/Query";
import { getLinkedinOrgData } from "~/utils/getLinkedinOrgData";
import PagesDailogBox from "./components/PagesDailogBox";
import { getFacebookProfilePages } from "~/utils/getFacebookProfilePages";
import { getTikTokProfileData } from "~/utils/getTiktokProfileData";
import { getInstagramProfileData } from "~/utils/getInstagramProfileData";
import { getTwitterProfileData } from "~/utils/getTwitterProfileData";

const ProfileConnectSocial = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("accesstoken");
  const profileId = searchParams.get("profileId");
  const platform = searchParams.get("platform");
  const accessToken_type = searchParams.get("accessToken_type");
  const type = searchParams.get("type");
  const navigate = useNavigate();
  const { brd_id } = useParams();
  const role = useUserDefaultRole();

  const client = useApolloClient();

  const [pages, setPages] = useState<{ [id: string]: string }>({});
  const [userName, setUserName] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: getallPlateforms, refetch: refetchAllPlateforms } = useQuery(
    GET_SOCIAL_AUTHENTICATION_INFO,
    {
      variables: { brd_id },
    }
  );

  useEffect(() => {
    if (token && platform === "LinkedIn" && type === "profileLogin") {
      getLinkedinProfileData(token, client).then((data: any) => {
        setProfileUrl(data?.image);
        setUserName(data?.name);
      });
    } else if (token && platform === "LinkedIn" && type === "page") {
      getLinkedinOrgData(token, client).then((data: any) => {
        setPages(data?.pagesData);
        setIsDialogOpen(true);
      });
    } else if (token && platform === "Facebook" && type === "page") {
      getFacebookProfilePages(token, client).then((data: any) => {
        setPages(data?.pagesData);
        setIsDialogOpen(true);
      });
    } else if (token && platform === "TikTok" && type === "profileLogin") {
      getTikTokProfileData(token, client).then((data: any) => {
        setProfileUrl(data?.profile_picture);
        setUserName(data?.display_name);
      });
    } else if (token && platform === "Instagram" && type === "profileLogin") {
      getInstagramProfileData(token, client).then((data: any) => {
        console.log("bbhwj", data);
        setProfileUrl(data?.image);
        setUserName(data?.name);
      })
    } else if (token && platform === "Twitter" && type === "profileLogin") {
      getTwitterProfileData(token, client).then((data: any) => {
        setProfileUrl(data?.profile_picture);
        setUserName(data?.display_name);
      });
    }
  }, [token, platform, type]);

  const [insertToken] = useMutation(INSERT_BRD_SOCIAL_AUTHENTICATION);
  const [updateToken] = useMutation(UPDATE_BRD_SOCIAL_AUTHENTICATION);

  useEffect(() => {
    if (token && profileId && userName && profileUrl && type) {
      updateToken({
        variables: {
          profile_id: profileId,
          brd_id: brd_id,
          data: { access_token: token },
        },
      }).then((data: any) => {
        if (data?.data?.update_brd_social_authentication?.affected_rows) {
          refetchAllPlateforms();
          toast.success("Token Updated Successfully");
          navigate(`/${role}/brands/${brd_id}/social-connect`);
        } else {
          insertToken({
            variables: {
              data: {
                profile_id: profileId,
                access_token: token,
                platform: platform,
                accessToken_type: accessToken_type,
                brd_id: brd_id,
                profile_picture: profileUrl,
                user_name: userName,
                platform_type: type,
              },
            },
          }).then((res: any) => {
            if (res?.data?.insert_brd_social_authentication?.affected_rows) {
              refetchAllPlateforms();
              toast.success(`${platform} Connected Successfully`);
              navigate(`/${role}/brands/${brd_id}/social-connect`);
            }
          });
        }
      });
    }
  }, [token, profileId, userName, profileUrl]);

  return (
    <div className="p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
        Manage Social Accounts
      </h1>
      <SocialAccountsTable
        data={getallPlateforms}
        refetchAllPlateforms={refetchAllPlateforms}
      />
      {type === "page" && (
        <PagesDailogBox
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          pages={pages}
          token={token}
          refetchAllPlateforms={refetchAllPlateforms}
          type={type}
          platform={platform}
        />
      )}
    </div>
  );
};

export default ProfileConnectSocial;
