import { gql } from "@apollo/client";

export const INSERT_ADMISSION_REQUIREMENTS = gql`
  mutation InsertAdmissionRequirements(
    $data: [def_admission_requirements_insert_input!]!
  ) {
    insert_def_admission_requirements(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_ADMISSION_REQUIREMENTS_WITH_ID = gql`
  mutation DeleteAdmissionRequirements($id: uuid!) {
    delete_def_admission_requirements(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_ADMISSION_REQUIREMENTS_WITH_ID = gql`
  mutation UpdateDefAdmissionRequirements(
    $id: uuid!
    $data: def_admission_requirements_set_input!
  ) {
    update_def_admission_requirements(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
      returning {
        gap_allowed
        grades
        qualification
        scholarship_available
      }
    }
  }
`;
