import { gql } from "@apollo/client";

export const INSERT_UNIVERSITY_COURSE = gql`
  mutation InsertUniversityCourse(
    $tuitionFees: String!
    $name: String!
    $universityId: uuid!
  ) {
    insert_def_university_courses(
      objects: {
        tuition_fees: $tuitionFees
        name: $name
        def_university_id: $universityId
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY_COURSE_WITH_ID = gql`
  mutation deleteUniversityCourseWithId($id: uuid!) {
    delete_def_university_courses(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_UNIVERSITY_COURSE_WITH_ID = gql`
  mutation UpdateDefUniversityCourses(
    $id: uuid!
    $data: def_university_courses_set_input!
  ) {
    update_def_university_courses(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        name
        tuition_fees
      }
    }
  }
`;
