import { gql } from "@apollo/client";

export const GET_UNIVERSITY_COURSES = gql`
  query getUniversityCourses {
    def_university_courses {
      name
      def_university_id
      id
      tuition_fees
    }
  }
`;
