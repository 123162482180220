export const emailSignatureTemp = () => {
  return `<br><br><br><br><br> <table
      style="
        width: 650px;
        margin: 50px auto;
        border-collapse: collapse;
        font-family: arial;
      "
    >
      <tbody>
        <tr style="vertical-align: top">
          <td style="padding: 5px 15px" width="100">
            <img
              style="width: 100px; height: 100px"
              src="user-img.png"
              alt=""
            />
          </td>
          <td style="padding: 5px 15px">
            <p
              style="font-size: 16px; font-weight: bold; color: #000; margin: 0"
            >
              Gary Somerhalder
            </p>
            <p style="font-size: 14px; color: #979797; margin: 0">
              Chief Executive Officer
            </p>
            <table style="width: 100%; margin-top: 15px">
              <tr>
                <td style="padding: 5px 0"><b>Mobile:</b> +1 615 495 6647</td>
              </tr>
              <tr>
                <td style="padding: 5px 0"><b>Direct:</b> +1 615 475 5782</td>
              </tr>
              <tr>
                <td style="padding: 5px 0">
                  <b>Main Toll-Free:</b> +1 855 257 3075
                </td>
              </tr>
              <tr>
                <td style="padding: 5px 0">
                  <b>Main International:</b> +1 615 475 5784
                </td>
              </tr>
            </table>
            <p style="margin: 5px 0 0 0">
              <a style="color: #008fdc; font-size: 16px" href="#"
                >gary@copilottravel.com</a
              >
            </p>
            <p style="margin: 5px 0 0 0">
              <a style="color: #008fdc; font-size: 16px" href="#"
                >copilottravel.com</a
              >
            </p>
            <p style="font-size: 16px; line-height: 1.5; color: #000">
              1550 West McEwen Drive, Suite 300 <br />
              Franklin, Tennessee 37067 <br />
              United States
            </p>
            <table style="width: 100%; margin-top: 15px">
              <tr>
                <td style="padding: 5px 0">
                  <b>Support Toll-Free:</b> +1 855 213 1443
                </td>
              </tr>
              <tr>
                <td style="padding: 5px 0">
                  <b>Support International:</b> +1 615 541 5849
                </td>
              </tr>
            </table>
            <p style="margin: 5px 0 0 0">
              <a style="color: #008fdc; font-size: 16px" href="#"
                >support@copilottravel.com</a
              >
            </p>
          </td>
        </tr>
        <tr style="vertical-align: top">
          <td style="padding: 5px 15px">
            <img
              width="159"
              style="margin-left: -70px"
              src="copilot-log.png"
              alt=""
            />
          </td>
          <td style="padding: 5px 15px">
            <p style="color: #008fdc; font-size: 16px; line-height: 1.5">
              Integrated technology to seamlessly connect <br />
              travel Suppliers, Merchants, and Consumers at <br />
              scale across any platform.
            </p>
            <a
              style="
                background: #008fdc;
                border-radius: 60px;
                color: #fff;
                text-decoration: none;
                padding: 14px 35px;
                margin-right: 15px;
                display: inline-block;
              "
              href="#"
              >Schedule Meeting</a
            >
            <a
              style="
                background: #d9d6e1;
                border-radius: 60px;
                color: #0b0b0b;
                text-decoration: none;
                padding: 14px 35px;
                display: inline-block;
              "
              href="#"
              >Try ITBE</a
            >
            <p style="color: #979797; font-size: 16px; line-height: 1.5">
              This message is intended for the personal and CONFIDENTIAL use of
              the designated recipient(s) named above. If you are not the
              intended recipient of this message, you are hereby notified that
              any review, dissemination, distribution, or copying is strictly
              prohibited. If you have received this communication in error,
              please destroy all electronic and paper copies and notify the
              sender immediately. Erroneous transmission is not intended to
              waive confidentiality or privilege.
            </p>
          </td>
        </tr>
      </tbody>
    </table>`;
};
