import { TextField, Button, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_UNIVERSITY_WITH_ID } from "~/graphql/def_universities/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

const EditUniversity = ({ university, setSidePopup }: any) => {

  const { register, handleSubmit, watch, reset }: any = useForm<FormData>();
  const [editUniversity] = useMutation(
    UPDATE_UNIVERSITY_WITH_ID
  );
  const client = useApolloClient()

  // const [campuses, setCampuses] = useState<any>([]);
  // const campusValue = watch("campus"); // Watching 'campus' input

  // useEffect(() => {
  //   const campusName = university?.def_university_campuses?.map((item: any) => {
  //     return item.name;
  //   });

  //   setCampuses(campusName);
  // }, [university]);

  // const handleAddCampus = () => {
  //   if (campusValue && !campuses.includes(campusValue)) {
  //     setCampuses((prev: any) => [...prev, campusValue]);
  //     reset({ campus: "" }); // Reset the campus input field after adding
  //   }
  // };

  // const handleDeleteCampus = (campusToDelete: any) => {
  //   setCampuses(campuses.filter((c: any) => c !== campusToDelete));
  // };

  // useEffect(() => {
  //   const handleKeyDown = (e: any) => {
  //     if (e.key === "Enter") {
  //       e.preventDefault();
  //       handleAddCampus(); // Add the campus on 'Enter' key press
  //     }
  //   };
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => window.removeEventListener("keydown", handleKeyDown);
  // }, [campusValue]);

  const onSubmit = async (formData: any) => {
    const res = await editUniversity({
      variables: {
        universityId: university?.id,
        name: formData?.university,
      },
    });

    if (res?.data?.update_def_universities?.affected_rows > 0) {
      toast.success("University Updated Successfully");
      client.refetchQueries({
        include: "active"
      })
      setSidePopup(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit University Data
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* University Name Input */}
        <TextField
          label="University Name"
          variant="outlined"
          fullWidth
          {...register("university")} // Registering the university input
          defaultValue={university?.name}
          style={{ marginBottom: "16px" }}
        />
        {/* <TextField
          label="Add Campus"
          variant="outlined"
          fullWidth
          {...register("campus")} // Registering the campus input
          style={{ marginBottom: "16px" }}
          helperText="Press Enter to add a Campus"
          InputProps={{
            startAdornment: campuses.map((campus: any, index: any) => (
              <Chip
                key={index}
                label={campus}
                onDelete={() => handleDeleteCampus(campus)} // Delete campus on Chip close
                style={{ marginRight: "8px", marginBottom: "8px" }}
              />
            )),
          }}
        /> */}

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditUniversity;
