import { gql } from "@apollo/client";

export const GET_ADMISSION_REQUIREMENTS = gql`
  query GetAdmissionRequirements {
    def_admission_requirements {
      qualification
      grades
      gap_allowed
      scholarship_available
      id
      def_university_course_id
    }
  }
`;
