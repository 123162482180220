import React, { useState } from "react";
import TicketRequestForm from "./components/TicketRequestForm";
import TicketRequestCard from "./components/TicketRequestCard";
import CloseInquiry from "./components/CloseInquiry";
import CompleteInquiry from "./components/CompleteInquiry";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SideDrawer from "~/components/common/sideDrawer";
import RefundInquiry from "./components/RefundInquiry";

export default function TicketRequest({ inquiry, transactions }: any) {
  const [hideForm, setHideForm] = useState(false);
  const role = useUserDefaultRole();
  const user_id = useUserId();

  const [sidePopup, setSidePopup] = useState<any>(false);

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
        {/* <div className="self-stretch flex justify-between pt-0 px-0 pb-[5px] items-center border-b-[1px] border-solid border-[#E7E3FC61]">
          <b className="text-basic font-medium mt-2">Ticket</b>
          <div className="flex w-[350px] gap-2">
            <div className="flex justify-end gap-3 w-full">
              {inquiry.status !== "refunded" && (
                <button
                  onClick={() => setSidePopup(true)}
                  className="block border border-basic rounded-full px-3 py-1 font-medium bg-basic text-white cursor-pointer hover:bg-white hover:dark:bg-dark-primary hover:text-basic"
                >
                  Refund and Close
                </button>
              )}
              {(user_id === inquiry?.picked_by || role === "admin") &&
                inquiry.status !== "completed" &&
                inquiry.status !== "refunded" && (
                  <CompleteInquiry inquiry={inquiry} />
                )}
            </div>
          </div>
        </div> */}

        <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
          <hr className="w-[2%] dark:border-[#E7E3FC61]" />
          <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
            TICKETS
          </span>
          <hr className="w-full dark:border-[#E7E3FC61]" />
          <div className="flex w-[350px] gap-2 pl-2 min-w-fit">
            <div className="flex justify-end gap-3 w-full">
              {inquiry.status !== "refunded" && (
                <button
                  onClick={() => setSidePopup(true)}
                  className="block border border-basic rounded-full px-3 py-1 font-medium bg-basic text-white cursor-pointer hover:bg-white hover:dark:bg-dark-primary hover:text-basic"
                >
                  Refund and Close
                </button>
              )}
              {(user_id === inquiry?.picked_by || role === "admin") &&
                inquiry.status !== "completed" &&
                inquiry.status !== "refunded" && (
                  <CompleteInquiry
                    inquiry={inquiry}
                    transactions={transactions}
                  />
                )}
            </div>
          </div>
        </div>

        {inquiry?.inq_tickets[0] ? (
          <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4  items-start justify-start gap-[30px] text-mini">
            <TicketRequestCard inquiry={inquiry} />
          </div>
        ) : (
          <TicketRequestForm inquiry={inquiry} setHideForm={setHideForm} />
        )}
      </div>
      <SideDrawer
        setSidePopup={setSidePopup}
        sidePopup={sidePopup}
        component={
          <RefundInquiry inquiry={inquiry} setSidePopup={setSidePopup} />
        }
      />
    </>
  );
}
