import SidePopup from "~/components/common/SidePopup";
import AddUniversity from "./components/AddUniversity";
import UniversitiesData from "./components/UniversitiesData";

const Universities = () => {
  return (
    <>
      <UniversitiesData />
      <SidePopup component={<AddUniversity />} />
    </>
  );
};

export default Universities;
