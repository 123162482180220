import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import useDefUniversitiesData from "~/hooks/useDefUniversitiesData";
import { DELETE_UNIVERSITY_WITH_ID } from "~/graphql/def_universities/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditUniversity from "../EditUniversity";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate and useParams

const UniversitiesData = () => {
  const { defaultUniversitiesData } = useDefUniversitiesData();
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState<any>(null); // To hold the selected university data
  const [deleteUniversity] = useMutation(DELETE_UNIVERSITY_WITH_ID);
  const client = useApolloClient();
  const navigate = useNavigate(); // Initialize useNavigate
  const { brd_id } = useParams(); // Get the dynamic brd_id from the URL

  const handleDeleteUniversity = async (id: any) => {
    const res = await deleteUniversity({
      variables: {
        id,
      },
    });

    if (res?.data?.delete_def_universities?.affected_rows > 0) {
      toast.success("University Deleted Successfully!");
      client.refetchQueries({
        include: "all",
      });
    }
  };

  const handleEditUniversity = (uni: any) => {
    setSelectedUniversity(uni); // Set the selected university
    setOpenPopup(true); // Open the popup
  };

  const handleViewCourses = (uni_id: any) => {
    navigate(`/admin/brands/${brd_id}/universities/${uni_id}/courses`);
  };

  const handleViewCampuses = (uni_id: any) => {
    navigate(`/admin/brands/${brd_id}/universities/${uni_id}/campuses`);
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Universities and Campuses</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>University Name</TableCell>
              {/* <TableCell>Campuses</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {defaultUniversitiesData?.def_universities?.map((uni: any) => (
              <TableRow key={uni.id}>
                <TableCell>{uni.name}</TableCell>
                {/* <TableCell>
                  {uni.def_university_campuses
                    .map((campus: any) => campus.name)
                    .join(", ")}
                </TableCell> */}
                <TableCell>
                  <div className="flex space-x-5">
                    <Button
                      onClick={() => {
                        handleEditUniversity(uni);
                      }}
                      color="primary"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        handleDeleteUniversity(uni.id);
                      }}
                      color="error"
                    >
                      Delete
                    </Button>
                    <Button
                      color="success"
                      onClick={() => handleViewCampuses(uni.id)}
                    >
                      View Campus
                    </Button>
                    <Button
                      color="info"
                      onClick={() => handleViewCourses(uni.id)}
                    >
                      View Courses
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditUniversity
              university={selectedUniversity}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </div>
  );
};

export default UniversitiesData;
