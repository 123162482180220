import { Autocomplete, TextField } from "@mui/material";
import brandsStore from "~/store/brandsStore/brandsStore";
import { useEffect, useState } from "react";
import { useUserId } from "@nhost/react";
import useAssigedBrands from "~/hooks/useAssigedBrands";

const Brandfilter = ({}: any) => {
  const userId = useUserId();

  const { brands: selectedBrands, setBrands }: any = brandsStore();

  const { brands } = useAssigedBrands();

  function getUniqueById(arr: any) {
    // Create a Map to store objects by their unique 'id' property
    const uniqueMap = new Map();

    // Iterate over each object in the array
    arr.forEach((obj: any) => {
      // Add each object to the Map with its 'id' as the key
      // This automatically overwrites any duplicate 'id' with the latest object
      uniqueMap.set(obj.id, obj);
    });

    // Convert the Map values back to an array
    return Array.from(uniqueMap.values());
  }

  useEffect(() => {
    if (brands.length > 0 && selectedBrands.length < 1) {
      setBrands(brands);
    }
  }, [brands]);

  const handleFlagChange = (event: any, newValue: any) => {
    const brandIds = newValue.map((item: any) => {
      return item.id;
    });

    const brandIds1: any = brands.map((item: any) => {
      return item.id;
    });

    const test = brandIds1.includes(...brandIds);

    console.log("jsgjdf", test, newValue);

    const uniqueArray = getUniqueById(newValue);
    setBrands(uniqueArray);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={brands || []}
        multiple
        sx={{
          width: "100%",
          // padding: "4px", // Apply padding to the Autocomplete component
          "& .MuiInputBase-root": {
            padding: "5px", // Apply padding to the input area inside Autocomplete
          },
        }}
        onChange={handleFlagChange}
        value={selectedBrands}
        getOptionLabel={(option: any) => option?.name}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
};

export default Brandfilter;
